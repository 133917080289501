import { graphql, navigate } from 'gatsby'
import { Strapi_NavigationItem, Strapi_SitemapPageEntityResponse } from 'gatsbyGraphqlTypes'
import React from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { NavigationItem, SitemapList } from '@hrk/huw-module-library'
import { getMappedNavigationItems } from './sitemap'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const SitemapEn = ({
  data,
}: {
  data: { strapi: { sitemapPage: { rootNavigationItems: Array<{ navigationItem: Strapi_NavigationItem }> } } }
}): JSX.Element => {
  const strapiData = data.strapi.sitemapPage as Strapi_SitemapPageEntityResponse
  const filteredItems = strapiData.data?.attributes?.rootNavigationItems?.filter((item) => {
    return item?.navigationItem?.data?.attributes
  })
  const mappedItems =
    filteredItems?.map((item) => {
      return { navigationItem: item?.navigationItem?.data?.attributes as Strapi_NavigationItem }
    }) ?? undefined
  const navigationItems: Array<NavigationItem> = getMappedNavigationItems(mappedItems)
  const currentSlug = '/en/sitemap'
  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: 'Sitemap', type: 'EditorialPages' })

  return (
    <LayoutWrapper
      currentTitle="Sitemap"
      currentLocale={GenerateLocales.en}
      currentSlug={currentSlug}
      currentPath={currentPagePath}
      otherLocales={[{ locale: GenerateLocales.de, slug: '/sitemap/' }]}
    >
      <>
        <h2>Sitemap</h2>
        <SitemapList navigate={navigate} data={navigationItems} />
      </>
    </LayoutWrapper>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/sitemap/`} />
}

export default SitemapEn

export const query = graphql`
  query SitemapEn {
    strapi {
      sitemapPage(locale: "en") {
        data {
          attributes {
            rootNavigationItems {
              navigationItem {
                data {
                  attributes {
                    externalLink
                    hasParent
                    status
                    targetPageContent {
                      data {
                        attributes {
                          slug
                          title
                          searchable
                          lastPublishedAt
                        }
                      }
                    }
                    subNavigationsItems {
                      navigationItem {
                        data {
                          attributes {
                            externalLink
                            hasParent
                            name
                            status
                            targetPageContent {
                              data {
                                attributes {
                                  slug
                                  title
                                  searchable
                                  lastPublishedAt
                                }
                              }
                            }
                            subNavigationsItems {
                              navigationItem {
                                data {
                                  attributes {
                                    externalLink
                                    hasParent
                                    name
                                    status
                                    targetPageContent {
                                      data {
                                        attributes {
                                          slug
                                          title
                                          searchable
                                          lastPublishedAt
                                        }
                                      }
                                    }
                                    subNavigationsItems {
                                      navigationItem {
                                        data {
                                          attributes {
                                            name
                                            externalLink
                                            hasParent
                                            status
                                            targetPageContent {
                                              data {
                                                attributes {
                                                  slug
                                                  title
                                                  searchable
                                                  lastPublishedAt
                                                }
                                              }
                                            }
                                            subNavigationsItems {
                                              navigationItem {
                                                data {
                                                  attributes {
                                                    name
                                                    externalLink
                                                    hasParent
                                                    status
                                                    targetPageContent {
                                                      data {
                                                        attributes {
                                                          slug
                                                          title
                                                          searchable
                                                          lastPublishedAt
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
