import { graphql, navigate } from 'gatsby'
import {
  Strapi_NavigationItem,
  Strapi_NavigationItemEntityResponse,
  Strapi_SitemapPageEntityResponse,
} from 'gatsbyGraphqlTypes'
import React from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { NavigationItem, SitemapList } from '@hrk/huw-module-library'
import { Seo } from '../components/seo/Seo'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

export const getMappedNavigationItems = (items: Array<{ navigationItem: Strapi_NavigationItem }> | undefined) => {
  if (items === undefined) {
    return undefined
  }
  return items
    .filter((item) => {
      return (
        !item.navigationItem.externalLink?.includes('/sitemap') &&
        !item.navigationItem.externalLink?.includes('/en/sitemap')
      )
    })
    .filter((item) => {
      // lastPublishedAt empty means it was never published, shall not be rendered
      // a page with status draft and lastPublishedAt null will not be rendered
      // a page with status draft and lastPublishedAt set will be rendered
      return item.navigationItem?.lastPublishedAt !== null
    })
    .filter((item) => {
      const erg =
        ((item.navigationItem?.targetPageContent?.data?.attributes?.searchable !== false &&
          item.navigationItem?.hasParent === true) ||
          item.navigationItem?.hasParent === false) ??
        false
      return erg
    }) // only those searchables, or home the route (having no parent)
    .map((item) => ({
      name: item.navigationItem?.name ?? item.navigationItem?.targetPageContent?.data?.attributes?.title,
      externalLink: item.navigationItem.externalLink,
      targetPageContent: item.navigationItem.targetPageContent,
      subNavigationsItems: getMappedNavigationItems(
        item.navigationItem.subNavigationsItems?.map((item) => {
          return { navigationItem: item?.navigationItem?.data?.attributes as Strapi_NavigationItem }
        }) ?? undefined,
      ),
    }))
}

const SitemapDe = ({
  data,
}: {
  data: {
    strapi: { sitemapPage: { rootNavigationItems: Array<{ navigationItem: Strapi_NavigationItemEntityResponse }> } }
  }
}): JSX.Element => {
  const strapiData = data.strapi.sitemapPage as Strapi_SitemapPageEntityResponse
  const filteredItems = strapiData.data?.attributes?.rootNavigationItems?.filter((item) => {
    return item?.navigationItem?.data?.attributes
  })
  const mappedItems =
    filteredItems?.map((item) => {
      return { navigationItem: item?.navigationItem?.data?.attributes as Strapi_NavigationItem }
    }) ?? undefined
  const navigationItems: Array<NavigationItem> = getMappedNavigationItems(mappedItems)
  const currentSlug = '/sitemap'
  const currentLocale: AppLocale = GenerateLocales.de
  const seoTitle = 'Sitemap'
  const seoDescription = 'Die Sitemap von hoch und weit'

  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })

  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <LayoutWrapper
        currentTitle={seoTitle}
        currentLocale={currentLocale}
        currentSlug={currentSlug}
        currentPath={currentPagePath}
        otherLocales={[{ locale: GenerateLocales.en, slug: '/en/sitemap/' }]}
      >
        <>
          <h2>{seoTitle}</h2>
          <SitemapList navigate={navigate} data={navigationItems} />
        </>
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/sitemap/`} />
}

export default SitemapDe

export const query = graphql`
  query SitemapDe {
    strapi {
      sitemapPage(locale: "de") {
        data {
          attributes {
            rootNavigationItems {
              navigationItem {
                data {
                  attributes {
                    externalLink
                    hasParent
                    status
                    lastPublishedAt
                    targetPageContent {
                      data {
                        attributes {
                          slug
                          title
                          searchable
                          lastPublishedAt
                        }
                      }
                    }
                    subNavigationsItems(
                      pagination: { limit: 10000 }
                      filters: { navigationItem: { status: { notIn: ["withdrawn", "error"] } } }
                    ) {
                      navigationItem {
                        data {
                          attributes {
                            externalLink
                            hasParent
                            name
                            status
                            lastPublishedAt
                            targetPageContent {
                              data {
                                attributes {
                                  slug
                                  title
                                  searchable
                                  lastPublishedAt
                                }
                              }
                            }
                            subNavigationsItems(
                              pagination: { limit: 10000 }
                              filters: { navigationItem: { status: { notIn: ["withdrawn", "error"] } } }
                            ) {
                              navigationItem {
                                data {
                                  attributes {
                                    externalLink
                                    hasParent
                                    name
                                    status
                                    lastPublishedAt
                                    targetPageContent {
                                      data {
                                        attributes {
                                          slug
                                          title
                                          searchable
                                          lastPublishedAt
                                        }
                                      }
                                    }
                                    subNavigationsItems(
                                      pagination: { limit: 10000 }
                                      filters: { navigationItem: { status: { notIn: ["withdrawn", "error"] } } }
                                    ) {
                                      navigationItem {
                                        data {
                                          attributes {
                                            name
                                            externalLink
                                            hasParent
                                            status
                                            lastPublishedAt
                                            targetPageContent {
                                              data {
                                                attributes {
                                                  slug
                                                  title
                                                  searchable
                                                  lastPublishedAt
                                                }
                                              }
                                            }
                                            subNavigationsItems(
                                              pagination: { limit: 10000 }
                                              filters: { navigationItem: { status: { notIn: ["withdrawn", "error"] } } }
                                            ) {
                                              navigationItem {
                                                data {
                                                  attributes {
                                                    name
                                                    externalLink
                                                    hasParent
                                                    status
                                                    lastPublishedAt
                                                    targetPageContent {
                                                      data {
                                                        attributes {
                                                          slug
                                                          title
                                                          searchable
                                                          lastPublishedAt
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
